<div class="button-container">
  <button
    type="type"
    [disabled]="disable"
    class="{{ className }}"
    attr.aria-label="{{ ariaLabel }}"
  >
    {{ label }}
  </button>
</div>
